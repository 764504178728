// In src/assets/main.scss

@import 'base/reset';
@import 'utils/variables';
@import 'base/typography';


html, body {
    height: 100%;
    
  }
  
  #root {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
  }
  
  .App {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
  }
  
  main {
    flex: 1 0 auto;
  }
  
  .footer {
    flex-shrink: 0;
  }