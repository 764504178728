// src/assets/components/_navbar.scss
@import '../utils/variables';
@import '../utils/mixins';

.navbar {
  --navbar-bg: #000000;
  --navbar-text: #ffffff;
  
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1000;
  background-color: var(--navbar-bg);
  color: var(--navbar-text);
  
  .navbar-container {
    display: grid;
    grid-template-columns: auto 1fr auto;
    align-items: center;
    gap: 2rem;
    padding: 1rem 2rem;
    max-width: 1200px;
    margin: 0 auto;
  }

  .navbar-logo {
    font-size: clamp(1.25rem, 2vw, 1.5rem);
    font-weight: 700;
    color: var(--navbar-text);
    text-decoration: none;
    transition: opacity 0.3s ease;
    
    &:hover {
      opacity: 0.8;
    }
  }

  .menu-icon {
    display: none;
    flex-direction: column;
    justify-content: space-between;
    width: 25px;
    height: 20px;
    cursor: pointer;
    
    span {
      display: block;
      height: 2px;
      background-color: var(--navbar-text);
      transition: transform 0.3s ease, opacity 0.3s ease;
    }
    
    &[aria-expanded="true"] {
      span:nth-child(1) { transform: translateY(9px) rotate(45deg); }
      span:nth-child(2) { opacity: 0; }
      span:nth-child(3) { transform: translateY(-9px) rotate(-45deg); }
    }
  }

  .nav-menu {
    display: flex;
    list-style: none;
    margin: 0;
    padding: 0;
    gap: 2rem;
    
    .nav-item {
      .nav-link {
        color: var(--navbar-text);
        text-decoration: none;
        font-weight: 500;
        padding: 0.5rem 0;
        position: relative;
        transition: opacity 0.3s ease;
        
        &::after {
          content: '';
          position: absolute;
          inset-inline: 0;
          bottom: 0;
          height: 2px;
          background-color: var(--navbar-text);
          transform: scaleX(0);
          transition: transform 0.3s ease;
        }
        
        &:hover,
        &:focus-visible,
        &.active {
          opacity: 0.8;
          
          &::after {
            transform: scaleX(1);
          }
        }
      }
    }
  }

  @include respond-to('medium') {
    .menu-icon {
      display: flex;
    }
    
    .nav-menu {
      position: absolute;
      top: 100%;
      left: 0;
      width: 100%;
      flex-direction: column;
      background-color: var(--navbar-bg);
      padding: 1rem 0;
      clip-path: polygon(0 0, 100% 0, 100% 0, 0 0);
      transition: clip-path 0.3s ease;
      
      &.active {
        clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
      }
      
      .nav-item {
        margin: 0.5rem 0;
        text-align: center;
      }
    }
  }
}

// Add this to your global styles or App.js component
body {
  padding-top: 60px; // Adjust this value based on your navbar height
}