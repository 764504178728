@import '../utils/variables';
@import '../utils/global';

.blog-container {
  @extend .page-container;

  .content-wrapper {
    @extend .content-wrapper;
  }

  h1 {
    color: $color-primary;
    font-size: $font-size-h1;
    text-align: center;
    margin-bottom: $spacing-large;
  }

  .blog-posts {
    display: flex;
    flex-direction: column;
    gap: $spacing-large;
  }

  .blog-post {
    background-color: $color-background;
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease, box-shadow 0.3s ease;

    &:hover {
      transform: translateY(-5px);
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
    }

    h2 {
      font-size: $font-size-h2;
      color: $color-secondary;
      margin: 0;
      padding: $spacing-medium;
      background-color: rgba($color-primary, 0.1);
    }

    .publish-date {
      font-size: $font-size-small;
      color: $color-text-dark;
      opacity: 0.7;
      margin: 0;
      padding: $spacing-small $spacing-medium;
    }

    .article-content {
      padding: $spacing-medium;
      
      img {
        max-width: 100%;
        height: auto;
        margin: $spacing-medium 0;
      }

      p, ul, ol {
        margin-bottom: $spacing-medium;
        color: $color-text-dark;
      }

      h1, h2, h3, h4, h5, h6 {
        margin-top: $spacing-large;
        margin-bottom: $spacing-small;
        color: $color-secondary;
      }
    }

    .read-more {
      display: block;
      text-align: center;
      background-color: $color-secondary;
      color: $color-text-light;
      text-decoration: none;
      padding: $spacing-small;
      transition: background-color 0.3s ease;

      &:hover {
        background-color: darken($color-secondary, 10%);
      }
    }
  }
}

@media (max-width: $breakpoint-medium) {
  .blog-container {
    h1 {
      font-size: $font-size-h2;
    }

    .blog-post h2 {
      font-size: $font-size-h3;
    }
  }
}