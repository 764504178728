// src/assets/utils/_global.scss

@import 'variables';

.page-container {
  width: 100%;
  max-width: 1600px;
  margin: 0 auto;
  padding: $spacing-large;
  background-color: $color-background-light;
}

.content-wrapper {
  background-color: $color-background;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  border-radius: 15px;
  overflow: hidden;
  padding: $spacing-large;
}