@import '../utils/variables';
@import '../utils/global';

.research-container {
    @extend .page-container;

  .content-wrapper {
    @extend .content-wrapper;
  }
  h1 {
    color: $color-primary;
    margin-bottom: $spacing-large;
  }

  h2 {
    color: $color-secondary;
    margin-top: $spacing-large;
    margin-bottom: $spacing-medium;
  }

  .research-summary {
    font-size: 1.1em;
    line-height: 1.6;

    margin-bottom: $spacing-large;
    padding: $spacing-medium;
    background-color: lighten($color-background-light, 5%);
    border-left: 4px solid $color-primary;
    border-radius: 4px;
  }

  .research-items {
    display: grid;
    gap: $spacing-large;
  }

  .research-item {
    background-color: $color-background;
    padding: $spacing-medium;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease, box-shadow 0.3s ease;

    &:hover {
      transform: translateY(-5px);
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
    }

    h3 {
      color: $color-secondary; 
      margin-bottom: $spacing-small;
      font-weight: $font-weight-bold; 
    }

    p {
      margin-bottom: $spacing-small;
      color: $color-text-dark; 

      &:last-child {
        margin-bottom: 0;
      }
    }

    em {
      font-style: italic;
      color: $color-primary; 
    }
  }

  .skills-list {
    display: flex;
    flex-wrap: wrap;
    gap: $spacing-small;
    margin-bottom: $spacing-large;

    .skill-item {
      background-color: $color-primary;
      color: $color-text-light;
      padding: $spacing-small $spacing-medium;
      border-radius: 20px;
      font-size: $font-size-small;
      transition: background-color 0.3s ease, transform 0.3s ease;

      &:hover {
        background-color: darken($color-primary, 10%);
        transform: translateY(-2px);
      }
    }
  }


}

@media (max-width: $breakpoint-xxlarge) {
    .research-items {
      grid-template-columns: 1fr;
    }
  }

  @media (min-width: $breakpoint-medium) and (max-width: $breakpoint-xxlarge) {
    .research-items {
      grid-template-columns: repeat(2, 1fr);
    }
  }

  @media (min-width: $breakpoint-xxlarge) {
    .research-items {
      grid-template-columns: repeat(3, 1fr);
    }
  }
