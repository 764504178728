@import '../utils/variables';
@import '../utils/global';

.testimonials-container {
  @extend .page-container;

  .content-wrapper {
    @extend .content-wrapper;
    text-align: center;
  }

  h1 {
    color: $color-primary;
    font-size: $font-size-h1;
    margin-bottom: $spacing-large;
  }

  .coming-soon-card {
    background-color: $color-background-light;
    border-radius: 8px;
    padding: $spacing-large;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease, box-shadow 0.3s ease;

    &:hover {
      transform: translateY(-5px);
      box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
    }

    h2 {
      color: $color-secondary;
      font-size: $font-size-h2;
      margin-bottom: $spacing-medium;
    }

    p {
      color: $color-text-dark;
      font-size: $font-size-base;
      margin-bottom: 0;
    }
  }
}

@media (max-width: $breakpoint-medium) {
  .testimonials-container {
    h1 {
      font-size: $font-size-h2;
    }

    .coming-soon-card {
      h2 {
        font-size: $font-size-h3;
      }

      p {
        font-size: $font-size-small;
      }
    }
  }
}