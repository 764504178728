@import '../utils/variables';
@import '../utils/global';

.cv-container {
  @extend .page-container;

  .content-wrapper {
    @extend .content-wrapper;
  }

  .cv-header {
    margin-bottom: $spacing-large;
  }

  .cv-download-btn {
    background-color: $color-primary;
    color: $color-text-light;
    padding: $spacing-small $spacing-medium;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;

    &:hover {
      background-color: darken($color-primary, 10%);
    }
  }

  .cv-section {
    margin-bottom: $spacing-large;

    h2 {
      color: $color-secondary;
      margin-bottom: $spacing-medium;
    }
  }

  .skills-list {
    display: flex;
    flex-wrap: wrap;
    gap: $spacing-small;

    .skill-item {
      background-color: $color-primary;
      color: $color-text-light;
      padding: $spacing-small $spacing-medium;
      border-radius: 20px;
      font-size: $font-size-small;
      transition: background-color 0.3s ease, transform 0.3s ease;

      &:hover {
        background-color: darken($color-primary, 10%);
        transform: translateY(-2px);
      }
    }
  }

  .experience-item,
  .research-item,
  .project-item,
  .education-item,
  .achievement-item {
    background-color: $color-background;
    padding: $spacing-medium;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    margin-bottom: $spacing-medium;
    transition: transform 0.3s ease, box-shadow 0.3s ease;

    &:hover {
      transform: translateY(-5px);
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
    }

    h3 {
      color: $color-secondary;
      margin-bottom: $spacing-small;
    }

    h4 {
      color: $color-primary;
      margin-bottom: $spacing-small;
    }

    p, ul {
      margin-bottom: $spacing-small;
      color: $color-text-dark;
    }

    em {
      font-style: italic;
      color: $color-primary;
    }
  }
}

@media (max-width: $breakpoint-medium) {
  .cv-container {
    .skills-list {
      .skill-item {
        font-size: $font-size-small * 0.9;
      }
    }
  }
}