// src/assets/components/_footer.scss
@import '../utils/variables';
@import '../utils/mixins';

.footer {
  background-color: $color-secondary;
  color: $color-background;
  padding: $spacing-large ;
  font-family: $font-family-base;
  font-size: $font-size-base;
  line-height: $line-height-base;

  .footer-container {
    max-width: 1200px;
    margin: 0 auto;
    padding: $spacing-large;  }

  .footer-main {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    gap: $spacing-large;
    padding-bottom: $spacing-large;
    border-bottom: $border-width solid rgba($color-background, 0.1);
  }

  .footer-section {
    h5 {
      color: $color-primary;
      font-size: $font-size-h4;
      margin-bottom: $spacing-medium;
      font-weight: $font-weight-bold;
    }
  }

  .footer-about {
    p {
      margin-bottom: $spacing-medium;
    }
  }

  .footer-links {
    ul {
      list-style-type: none;
      padding: 0;

      li {
        margin-bottom: $spacing-small;

        a {
          color: $color-background;
          text-decoration: none;
          transition: color 0.3s ease;

          &:hover {
            color: $color-primary;
          }
        }
      }
    }
  }

  .footer-social {
    .social-icons {
      display: flex;
      flex-direction: column;
      gap: $spacing-small;

      a {
        color: $color-background;
        text-decoration: none;
        transition: color 0.3s ease;

        &:hover {
          color: $color-primary;
        }
      }
    }
  }

  .footer-newsletter {
    p {
      margin-bottom: $spacing-medium;
    }

    .btn-primary {
      background-color: $color-primary;
      color: $color-background;
      border: none;
      padding: $spacing-small $spacing-medium;
      font-size: $font-size-base;
      font-weight: $font-weight-bold;
      cursor: pointer;
      transition: background-color 0.3s ease;
      text-decoration: none;
      display: inline-block;

      &:hover {
        background-color: darken($color-primary, 10%);
      }
    }
  }

  .footer-bottom {
    margin-top: $spacing-large;
    text-align: center;
    font-size: $font-size-base * 0.9;
    color: rgba($color-background, 0.7);
  }

  @include respond-to('medium') {
    .footer-main {
      grid-template-columns: 1fr;
      text-align: center;
    }

    .footer-section {
      margin-bottom: $spacing-large;
    }

    .footer-social {
      .social-icons {
        flex-direction: row;
        justify-content: center;
      }
    }

    .footer-newsletter {
      .btn-primary {
        display: block;
        width: 100%;
      }
    }
  }
}