// src/assets/utils/_variables.scss

// Colors
$color-primary: #3498db;
$color-secondary: #2c3e50;
$color-background: #ffffff; 
$color-background-light: #ffffff;
$color-background-dark: #1a1a1a;
$color-text-dark: #333333;
$color-text-light: #ffffff;
$color-card-background: #ffffff;
// $color-background-light: #f8f9fa;  // Very light gray

// Typography
$font-family-base: 'Arial', sans-serif;
$font-size-base: 1rem;
$line-height-base: 1.5;
$font-size-small: 0.875rem;

$font-size-h1: 2.5rem;
$font-size-h2: 1.8rem;
$font-size-h3: 1.5rem;
$font-size-h4: 1.2rem;
$font-size-p: 1rem;

$font-weight-normal: 400;
$font-weight-bold: 700;

// Spacing
$spacing-small: 0.5rem;
$spacing-medium: 1rem;
$spacing-large: 2rem;

// Border
$border-color: #333333;
$border-width: 2px;

// Breakpoints (if you're using them in your mixins)
$breakpoint-small: 576px;
$breakpoint-medium: 768px;
$breakpoint-large: 992px;
$breakpoint-xlarge: 1200px;
$breakpoint-xxlarge: 1600px;










