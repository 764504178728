/* Reset default styles */
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
  
  /* Reset lists */
  ul, ol {
    list-style: none;
  }
  
  /* Reset links */
  a {
    text-decoration: none;
    color: inherit;
  }
  
  /* Reset form elements */
  input, button, textarea, select {
    font: inherit;
  }
  
  /* Improve text rendering */
  body {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }