/* Import Google Fonts if needed */
@import url('https://fonts.googleapis.com/css2?family=Your+Font+Family&display=swap');

/* Define font variables */
$font-primary:  sans-serif;
$font-secondary:  serif;

/* Define font sizes */
$font-size-base: 16px;
$font-size-small: 0.875rem;
$font-size-large: 1.25rem;
$font-size-xlarge: 1.5rem;

/* Define font weights */
$font-weight-normal: 400;
$font-weight-bold: 700;

/* Define line heights */
$line-height-base: 1.5;
$line-height-heading: 1.2;

/* Typography styles */
body {
  font-family: $font-primary;
  font-size: $font-size-base;
  line-height: $line-height-base;
}

h1, h2, h3, h4, h5, h6 {
  font-family: $font-secondary;
  line-height: $line-height-heading;
  margin-bottom: 0.5em;
}

h1 { font-size: 2.5rem; }
h2 { font-size: 2rem; }
h3 { font-size: 1.75rem; }
h4 { font-size: 1.5rem; }
h5 { font-size: 1.25rem; }
h6 { font-size: 1rem; }

p {
  margin-bottom: 1em;
}

// Global layout for consistent page structure
.page-content {
    max-width: 1200px;
    margin: 0 auto;
    padding: $spacing-large;
    background-color: $color-background;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }
  
  // Responsive adjustments
  @media (max-width: $breakpoint-medium) {
    .page-content {
      padding: $spacing-medium;
    }
  }