@import '../utils/variables';
@import '../utils/global';

.home-container {
  @extend .page-container;

  .content-wrapper {
    @extend .content-wrapper;
    display: flex;
    flex-direction: column;
  }

  .intro-section {
    display: flex;
    margin-bottom: $spacing-large;
  }

  .image-container {
    flex: 0 0 40%;
    max-width: 40%;
  }

  .profile-image {
    width: 100%;
    height: auto;
    object-fit: cover;
    border-radius: 10px;
  }

  .text-container {
    flex: 1;
    padding-left: $spacing-large;
  }

  h1 {
    color: $color-primary;
    font-size: $font-size-h1;
    margin-bottom: $spacing-small;
  }

  h2 {
    color: $color-secondary;
    font-size: $font-size-h3;
    margin-bottom: $spacing-medium;
  }

  p {
    color: $color-text-dark;
    font-size: $font-size-base;
    line-height: $line-height-base;
    margin-bottom: $spacing-medium;
  }

  .button-container {
    margin-top: $spacing-large;
  }

  .btn {
    padding: $spacing-small $spacing-medium;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: $font-size-base;
    transition: background-color 0.3s ease;

    &.primary {
      background-color: $color-primary;
      color: $color-text-light;
      margin-right: $spacing-medium;

      &:hover {
        background-color: darken($color-primary, 10%);
      }
    }

    &.secondary {
      background-color: transparent;
      color: $color-secondary;
      border: 1px solid $color-secondary;

      &:hover {
        background-color: $color-secondary;
        color: $color-text-light;
      }
    }
  }

  .research-section {
    padding: $spacing-large;
    background-color: lighten($color-background-light, 2%);
    
    h1 {
      font-size: $font-size-h2;
      margin-bottom: $spacing-medium;
    }

    .research-summary {
      font-size: 1em;
      line-height: 1.5;
      margin-bottom: $spacing-large;
    }

    h2 {
      font-size: $font-size-h3;
      margin-top: $spacing-medium;
      margin-bottom: $spacing-small;
    }

    .skills-list {
      display: flex;
      flex-wrap: wrap;
      gap: $spacing-small;
      margin-bottom: $spacing-medium;

      .skill-item {
        background-color: $color-primary;
        color: $color-text-light;
        padding: $spacing-small / 2 $spacing-small;
        border-radius: 15px;
        font-size: $font-size-small;
      }
    }

    .research-items {
      display: grid;
      gap: $spacing-medium;
      grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    }

    .research-item {
      background-color: $color-background;
      padding: $spacing-small;
      border-radius: 8px;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

      h3 {
        font-size: $font-size-base;
        color: $color-secondary;
        margin-bottom: $spacing-small / 2;
      }

      p {
        font-size: $font-size-small;
        margin-bottom: $spacing-small / 2;
      }
    }
  }
}

@media (max-width: $breakpoint-medium) {
  .home-container {
    .intro-section {
      flex-direction: column;
    }

    .image-container {
      flex: 0 0 100%;
      max-width: 100%;
      margin-bottom: $spacing-large;
    }

    .text-container {
      padding-left: 0;
    }

    .research-section {
      .research-items {
        grid-template-columns: 1fr;
      }
    }
  }
}